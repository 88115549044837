import React from 'react'

const RedFrame = () => {
  return (
    <div>
  <svg xmlns="http://www.w3.org/2000/svg" width="689" height="982" viewBox="0 0 689 982" fill="none">
<g clipPath="url(#clip0_907_11477)">
<rect width="689" height="982" fill="#ED1A3B"/>
<path d="M369.299 947.695L295.083 774.502C293.169 767.908 291.852 761.401 291.852 754.858C291.852 747.664 295.073 736.042 299.911 726.546L368.028 615.328L368.041 615.307L368.052 615.285C372.984 605.505 382.955 598.887 394.835 598.418L394.837 598.418C411.504 597.688 425.691 609.033 426.429 626.547C426.454 628.285 426.257 631.086 425.834 633.891C425.409 636.702 424.768 639.434 423.936 641.097L361.708 740.9L361.704 740.906C358.26 746.58 356.779 751.679 356.573 756.456C356.368 761.228 357.437 765.637 359.032 769.926L359.036 769.937L453.228 1008.89H62.6709C27.8926 1008.89 0.975389 981.193 0.97539 948.197L0.975389 948.196L0.600683 629.031C0.839531 618.884 4.73918 611.271 10.4409 606.177C16.1533 601.074 23.7063 598.471 31.2794 598.419C38.8524 598.367 46.4128 600.865 52.1424 605.921C57.861 610.967 61.7916 618.592 62.0702 628.873V928.026C62.0158 932.945 64.1586 938.128 67.7187 942.046C71.283 945.969 76.3091 948.667 82.0515 948.532H368.747H369.658L369.299 947.695ZM209.16 842.964V628.875C209.16 618.592 212.936 610.906 218.574 605.78C224.222 600.646 231.775 598.05 239.367 598.031C246.96 598.011 254.557 600.568 260.292 605.694C266.016 610.811 269.921 618.518 270.094 628.879V845.3C270.094 894.458 233.086 926.959 187.686 926.959C142.344 926.959 105.225 893.665 105.225 845.3V628.875C105.225 618.594 109.043 610.929 114.734 605.828C120.436 600.718 128.055 598.15 135.692 598.15C143.33 598.15 150.949 600.718 156.65 605.828C162.342 610.929 166.159 618.594 166.159 628.875V842.964C166.159 857.686 176.911 865.53 187.647 865.755C193.01 865.868 198.393 864.082 202.439 860.292C206.493 856.494 209.16 850.726 209.16 842.964Z" stroke="white" strokeOpacity="0.3" strokeWidth="1.20135"/>
<path d="M400.535 921.265L326.318 748.072C324.405 741.478 323.087 734.971 323.087 728.428C323.087 721.234 326.309 709.612 331.146 700.116L399.263 588.898L399.276 588.877L399.287 588.855C404.219 579.075 414.19 572.457 426.07 571.988L426.072 571.988C442.739 571.258 456.926 582.603 457.664 600.117C457.69 601.855 457.492 604.656 457.069 607.461C456.644 610.272 456.003 613.005 455.172 614.667L392.943 714.47L392.939 714.476C389.495 720.15 388.014 725.249 387.809 730.026C387.603 734.798 388.672 739.207 390.267 743.496L390.271 743.507L484.463 982.462H93.906C59.1277 982.462 32.2105 954.763 32.2105 921.767L32.2105 921.766L31.8358 602.601C32.0746 592.454 35.9743 584.841 41.676 579.747C47.3884 574.644 54.9414 572.041 62.5145 571.989C70.0875 571.937 77.6479 574.436 83.3775 579.491C89.0961 584.538 93.0267 592.162 93.3053 602.444V901.596C93.2509 906.516 95.3938 911.699 98.9538 915.617C102.518 919.539 107.544 922.237 113.287 922.102H399.982H400.893L400.535 921.265ZM240.395 816.534V602.445C240.395 592.162 244.171 584.476 249.809 579.351C255.457 574.216 263.01 571.62 270.602 571.601C278.195 571.581 285.792 574.138 291.527 579.264C297.252 584.381 301.156 592.088 301.329 602.449V818.87C301.329 868.028 264.321 900.529 218.921 900.529C173.579 900.529 136.46 867.235 136.46 818.87V602.445C136.46 592.164 140.278 584.499 145.969 579.398C151.671 574.288 159.29 571.72 166.927 571.72C174.565 571.72 182.184 574.288 187.886 579.398C193.577 584.499 197.395 592.164 197.395 602.445V816.534C197.395 831.256 208.146 839.1 218.882 839.325C224.245 839.438 229.628 837.652 233.674 833.862C237.728 830.064 240.395 824.296 240.395 816.534Z" stroke="white" strokeOpacity="0.3" strokeWidth="1.20135"/>
<path d="M46.2167 477.14H54.226V498.72H62.8069V505.535H46.2167V477.14Z" fill="white"/>
<path d="M66.0277 494.054V477.14H74.037V493.892C74.037 495.637 74.3647 499.529 78.6133 499.529C82.8655 499.529 83.1931 495.637 83.1931 493.892V477.14H91.2024V494.054C91.2024 497.097 90.5471 506.344 78.6133 506.344C66.683 506.344 66.0277 497.097 66.0277 494.054Z" fill="white"/>
<path d="M96.4236 477.14H104.433V489.065L113.057 477.14H122.458L111.587 490.889L122.741 505.535H112.73L104.433 493.325V505.535H96.4236V477.14ZM159.096 477.139H167.105V505.533H159.096V477.139ZM172.973 477.14H180.982V498.72H189.567V505.535H172.973V477.14Z" fill="white"/>
<path d="M146.035 491.357C146.035 485.753 142.562 482.974 138.757 482.974C134.953 482.974 131.48 485.753 131.48 491.357C131.48 496.962 134.953 499.74 138.757 499.74C142.562 499.74 146.035 496.962 146.035 491.357ZM123.033 491.357C123.033 483.595 128.054 476 138.757 476C149.464 476 154.481 483.595 154.481 491.357C154.481 499.119 149.464 506.714 138.757 506.714C128.054 506.714 123.033 499.119 123.033 491.357Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_907_11477">
<rect width="689" height="982" fill="white"/>
</clipPath>
</defs>
</svg>
  </div>
)};

export default RedFrame;
